table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}
table {
  border-collapse: collapse;
}
.upohver {
  color: blue;
  font-weight: bold;
  cursor: pointer;
}
.upohver:hover {
  text-decoration: underline;
}
.accessbtnnegativ {
  background: #f06548;
  max-height: 30px;
  border: none;
  color: rgb(255, 255, 255);
  width: 80px;
  border-radius: 5px;
  height: 25px;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
thead {
  position: sticky;
  top: 0px;
  background-color: rgb(18, 70, 138);
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  z-index: 5;
  border-bottom: 1px solid rgb(221, 221, 221);

  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 6px, rgba(0, 0, 0, 0.05) 0px 3px 5px;
}
.accessbtnnegativ:hover {
  box-shadow: 2px 7px 5px #e39383;
  transform: scale(1.02) rotate(-0.5deg);
}
.tabwrap {
  width: 100%;
}
.tabwrap table {
  width: 100%;
}
.parenttabwrap {
  width: 100%;
  overflow-x: auto;
  border-radius: 10px;
}

td {
  font-size: 12px;
  color: #2e2f30;
  padding: 0.3rem;
  font-weight: normal;
  border: 1px solid #ddd;
  text-align: left;
  text-wrap: nowrap;
}
th {
  font-size: 12px;
  padding: 10px;
  border: 1px solid #ddd;
  font-weight: 600;
  color: #fff;
  text-align: left;
  text-wrap: nowrap;
}
.accessbtn {
  max-height: 30px;
  border: none;
  color: rgb(255, 255, 255);
  width: 80px;
  border-radius: 5px;
  height: 25px;
  background-color: rgb(41, 156, 216);
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.accessbtn:hover {
  box-shadow: 2px 7px 5px rgb(124, 190, 226);
  transform: scale(1.02) rotate(-0.5deg);
}
.editbtn {
  padding: 2px 5px;
  color: #fff;
  background-color: #7bdaa9;
  border: none;
  border-radius: 3.5px;
  margin-right: 7px;
  height: 23px;
  width: 23px;
  cursor: pointer;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.editbtn:hover {
  box-shadow: 2px 7px 5px rgba(99, 99, 99, 0.2);
  transform: scale(1.02) rotate(-0.5deg);
}
.dltbtn:hover {
  box-shadow: 2px 7px 5px rgba(99, 99, 99, 0.2);
  transform: scale(1.02) rotate(-0.5deg);
}
.viewbtn:hover {
  box-shadow: 2px 7px 5px rgba(99, 99, 99, 0.2);
  transform: scale(1.02) rotate(-0.5deg);
}

.dltbtn {
  padding: 2px 5px;
  color: #fff;
  background-color: #f06548;
  border: none;
  border-radius: 3.5px;
  height: 23px;
  width: 23px;
  cursor: pointer;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.viewbtn {
  padding: 2px 5px;
  color: #fff;
  background-color: #ffca00;
  border: none;
  border-radius: 3.5px;
  height: 23px;
  width: 23px;
  cursor: pointer;
  margin-right: 7px;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.btnn_1 {
  padding: 2px 5px;
  color: #fff;
  background-color: #ff960f;
  border: none;
  border-radius: 3.5px;
  height: 23px;
  width: 23px;
  cursor: pointer;
  margin-right: 7px;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.btnn_1:hover {
  box-shadow: 2px 7px 5px rgba(99, 99, 99, 0.2);
  transform: scale(1.02) rotate(-0.5deg);
}
.btnn_2 {
  padding: 2px 5px;
  color: #fff;
  background-color: #f5541b;
  border: none;
  border-radius: 3.5px;
  height: 23px;
  width: 23px;
  cursor: pointer;
  margin-right: 7px;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.btnn_2:hover {
  box-shadow: 2px 7px 5px rgba(99, 99, 99, 0.2);
  transform: scale(1.02) rotate(-0.5deg);
}
.btnn_3 {
  padding: 2px 5px;
  color: #fff;
  background-color: #62a3c1;
  border: none;
  border-radius: 3.5px;
  height: 23px;
  width: 23px;
  cursor: pointer;
  margin-right: 7px;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.btnn_3:hover {
  box-shadow: 2px 7px 5px rgba(99, 99, 99, 0.2);
  transform: scale(1.02) rotate(-0.5deg);
}
