.searchformasc {
  background-color: #fff;
  padding: 14px;
  display: grid;
  flex-direction: column;
  gap: 15px;
  font-family: Inter, sans-serif;
}

.uploadbx {
  background-color: #fff;
  color: #212529;
  font-size: 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #ced4da;
  /* box-shadow: 5px 5px 10px #000; */
}
::-webkit-file-upload-button {
  color: #212529;
  background-color: #eff2f7;
  padding: 5px;
  border: #ced4da;
  border-radius: 5px 0px 0px 5px;
  outline: none;
  font-size: 11px;
  width: 80px;

  height: 24px;
}
.totalcheckconnn {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}
.totalcheckconnnfth {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 15px;
}
.mdselecttt {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 30px;
  font-size: 12px;
  outline: #ced4da;
  padding: 5px;
  color: #494646;
  width: 100%;
}
.bbtnvofdevd {
  margin-left: 5px;
  color: #fff;
  background-color: #12468a;
  font-size: 10px;
  padding: 3px 10px;
  border-radius: 3px;
  border: none;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
}
.desdvvfremidata {
  color: #fff;
  background-color: #45cb85;
  font-size: 15px;
  text-align: center;
  vertical-align: middle;
  padding-top: 15px;
  border: none;
  border-radius: 10px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
  height: 35px;
}
.bbtnvofdevd:hover {
  box-shadow: 2px 5px 5px #b7c9e3;
  transform: scale(1.02) rotate(-0.5deg);
}
.bbtnvofde {
  margin-left: 5px;
  color: #fff;
  background-color: #12468a;
  font-size: 10px;
  padding: 3px 10px;
  border-radius: 3px;
  border: none;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
}
.bbtnvofde:hover {
  box-shadow: 2px 5px 5px #b7c9e3;
  transform: scale(1.02) rotate(-0.5deg);
}
.bbtfnvogfde:hover {
  box-shadow: 2px 5px 5px #b7c9e3;
  transform: scale(1.02) rotate(-0.5deg);
}
.bbtfnvogfde {
  color: #fff;
  background-color: #12468a;
  font-size: 10px;
  padding: 3px 5px;
  border-radius: 3px;
  border: none;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
}
.requ {
  color: red;
  font-size: 13px;
  padding-left: 2px;
}
.error {
  color: red;
  font-size: 11px;
  padding-top: 1px;
}
.fourinputasc {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  width: 100%;
}
.threeinputads {
  display: flex;
  gap: 20px;
  width: 74.5%;
}
.oneinputads {
  display: flex;
  gap: 20px;
  width: 23.5%;
}
.twoinputasc {
  display: flex;
  gap: 20px;
  width: 49%;
}
.searorinp {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  text-align: left;
}
.inpttsc {
  border-radius: 5px;
  border: 1px solid #e2e1e0;
  height: 18px;
  font-size: 11px;
  color: #6d6d6d;
  padding: 5px;
  font-family: Inter, sans-serif;
}
.inputstst {
  border-radius: 5px;
  border: 1px solid #e2e1e0;
  height: 15px;
  font-size: 11px;
  color: #6d6d6d;
  padding: 5px;
  font-family: Inter, sans-serif;
}
.selctasc {
  border-radius: 5px;
  border: 1px solid #e2e1e0;
  /* width: 100%; */
  height: 30px;
  font-size: 11px;
  color: #6d6d6d;
  padding: 5px;
  font-family: Inter, sans-serif;
}
.optionnsel {
  font-size: 11px;
  font-family: open sans;
  padding: 5px;
  height: 20px;
}
.inputasc {
  font-family: open sans;
  border-radius: 5px;
  border: 1px solid #e2e1e0;
  width: 100%;
  height: 33px;
  font-size: 11px;
  color: #6d6d6d;
  padding: 0 5px;
}
.buttonasc {
  display: flex;
  gap: 20px;
}
.uplodflex {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.chosefil {
  padding: 2px 6px;
  border: solid 1px #dadada;
  width: 230px;
  font-size: 10px;
}
.downloadssam {
  font-size: 9px;
  padding: 2px;
  width: 100px;
  max-height: 25px;
  color: rgb(69, 159, 160);
  border: 1px solid #000;
  border-radius: 2px;
}
.butncon {
  display: flex;
  gap: 10px;
}
.Subbtn {
  width: 100px;
  color: #fff;
  cursor: pointer;
  border: none;
  font-size: 12px;
  margin: 10px 0;
  padding: 10px;
  background-color: #12468a;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
}
.Subbtn:hover {
  box-shadow: 2px 5px 5px #b7c9e3;
  transform: scale(1.02) rotate(-0.5deg);
}
