.text-editor {
  width: 100%;
  /* margin: 0 auto; */
}
.ql-editor {
  min-height: 300px;
  width: 100%;
}
.ql-formats-border:not(:last-child) {
  border-right: 1px solid #ccc;
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 10px;
}
/* Font class for Arial */
.ql-font-arial {
  font-family: "Arial", sans-serif;
}

/* Font class for Georgia */
.ql-font-georgia {
  font-family: "Georgia", serif;
}

/* Font class for Helvetica */
.ql-font-helvetica {
  font-family: "Helvetica", sans-serif;
}

/* Font class for Courier New */
.ql-font-courier-new {
  font-family: "Courier New", monospace;
}

/* Font class for Comic Sans */
.ql-font-comic-sans {
  font-family: "Comic Sans MS", cursive;
}

/* Font class for Lucida */
.ql-font-lucida {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}
