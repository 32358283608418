.modalcontainer {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  border-radius: 15px;

  width: 500px;
  border-bottom: 1px solid #dee2e6;
}
.uploadbx {
  background-color: #fff;
  color: #212529;
  font-size: 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #ced4da;
  /* box-shadow: 5px 5px 10px #000; */
}
::-webkit-file-upload-button {
  color: #212529;
  background-color: #eff2f7;
  padding: 5px;
  border: #ced4da;
  border-radius: 5px 0px 0px 5px;
  outline: none;
  font-size: 11px;
  width: 80px;

  height: 24px;
}
.mdselct {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 30px;
  font-size: 12px;
  outline: #ced4da;
  padding: 5px;
}
.modalHeader {
  align-items: flex-start;
  /* border-bottom: 1px solid #dee2e6; */
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: #12468a;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  border-radius: 10px 10px 0 0;
  margin-bottom: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 6px, rgba(0, 0, 0, 0.05) 0px 3px 5px;
}
.modalheading {
  line-height: 1.5px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
}
.modalbody {
  flex: 1 1 auto;
  padding: 5px 15px;
  position: relative;
  /* border-bottom: 1px solid #dee2e6; */
}
.modalform {
  text-align: left;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}
.mdlinput {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 20px;
  font-size: 12px;
  outline: #ced4da;
  padding: 5px;
}
.mdselecttt {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 30px;
  font-size: 12px;
  outline: #ced4da;
  padding: 5px;
  color: #494646;
}
.closebtn {
  background-color: #45cb85;
  width: 80px;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 6px;
  font-size: 12px;
  padding: 6px 10px;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
}
.closebtn:hover {
  box-shadow: 2px 5px 5px #74ad90;
  transform: scale(1.02) rotate(-0.5deg);
}
.Subbtn {
  background-color: #12468a;
  color: #fff;
  border: none;
  width: 80px;
  font-size: 12px;
  padding: 6px 10px;
  border-radius: 100px;
  cursor: pointer;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
}
.Subbtn:hover {
  box-shadow: 2px 5px 5px #b7c9e3;
  transform: scale(1.02) rotate(-0.5deg);
}
.error {
  color: red;
  font-size: 11px;
  padding-top: 5px;
}
