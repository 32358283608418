.monthBoxes {
  display: flex;
  width: 100%;
  gap: 10px;
  text-wrap: wrap;
}

.monthntn {
  color: #212529;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  border: none;
  font-size: 13px;
  width: 100%;
  height: 35px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.selectMonthBtn {
  color: #fff;
  background: #25a546;
  padding: 5px;
  border-radius: 5px;
  border: none;
  font-size: 13px;
  width: 100%;
  height: 35px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
  width: 100%;
  height: 35px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
  background-color: #12468a;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  color: #fff;
  border: none;
  font-size: 13px;
  padding: 8px;
  border-radius: 7px;
  cursor: pointer;
}
.monthntn:hover {
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
  width: 100%;
  height: 35px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
  background-color: #12468a;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  color: #fff;
  border: none;
  font-size: 13px;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  transform: scale(1.01);
  transition: all 0.3s ease-out;
}
.ttldv {
  display: flex;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: transform 0.3s ease-out;
  cursor: pointer;
}
.textconfor {
  width: 100%;
  text-align: left;
}
.btmoftot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icocont {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: #fff;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.2);
}
.icocontforbt {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: #fff;
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.2);
}
.ttldv:hover {
  transform: translateY(-2px);
}
.chart-container {
  width: 100%;
  position: relative;
}
