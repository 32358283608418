.main-body {
  /* height: 100%; */
  padding: 15px;
  background-color: #f2f2f7;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  display: grid;
}
/* .main-body h3,
.main-body h4 {
  color: #1c9294;
} */

.container {
  width: 100%;
}
.tablco {
  /* padding: 10px; */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 5px 10px;
}
.toptext {
  text-align: left;
  color: rgb(73, 80, 87);
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
  border-bottom: 1px solid #e9ebec;
}
.btnco {
  padding: 15px;
  height: 420px;
  overflow-y: scroll;
  border-bottom: 1px solid #e9ebec;
}
.totalcheckcon {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
}
.checkboxcont {
  padding: 10px;
  border: 1px solid #e9ebec;
  /* width: 100%; */
  min-width: 150px;
}
.checkboxheader {
  border-bottom: 1px solid #e9ebec;
  text-align: left;
  display: flex;
  gap: 10px;
  padding: 5px 0;
  font-size: 14px;
  align-items: center;
}
.checksubmenucon {
  display: flex;
  flex-direction: column;
}
.submenucheck {
  text-align: left;
  display: flex;
  gap: 10px;
  padding: 5px 0;
  font-size: 13px;
  align-items: center;
}
.Subbtn {
  width: 100px;
  color: #fff;
  cursor: pointer;
  border: none;
  font-size: 12px;
  margin: 10px 15px;
  padding: 10px;
  background-color: #12468a;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
}
.Subbtn:hover {
  box-shadow: 2px 5px 5px #b7c9e3;
  transform: scale(1.02) rotate(-0.5deg);
}
