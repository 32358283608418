body {
  margin: 0;
}

main {
  margin: 16px;
}
.proco {
  font-size: 12px;
  text-align: left;
  padding-left: 10px;
  color: #212529;
  font-family: "Inter", sans-serif;
}
.proco:hover {
  background-color: #eff2f7;
}

/* .navbar {
  display: flex;#eff2f7
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  background: #f6f6f6;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.15);
  line-height: 35px;
  font-size: 12px;
} */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 50px;
  background-color: #fff;
  padding: 0 1.5rem 0 calc(1.5rem * 0.5);
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.navbar__title {
  margin-right: auto;
  cursor: pointer;
}

.navbar__item {
  padding: 8px;
  cursor: pointer;
  font-size: 17px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  /* max-width: 17px; */
}
.navbar__user img {
  width: 20px;
  height: 20px;
}
.navbar-name {
  font-size: 12px;
}
.navbar__user {
  font-size: 16px;
  padding: 9px 15px;
  display: flex;
  /* gap: 5px; */
  align-items: center;
  position: relative;
}
.branchSubmitBtn button {
  font-size: 14px;
  min-width: 135px;
  height: 40px;
  width: auto;
  background: #1c9294;
  color: #fff !important;
  border: solid 1px;
  font-size: 14px;

  border-radius: 4px;
}
.menuIcon {
  padding: 5px 10px;
  cursor: pointer;
}
.menuIcon:hover,
.navbar__user:hover,
.navbar__item:hover {
  /* background: rgba(0, 0, 0, 0.1); */
  border-top: solid 2px #fff;
}
.header_dropdown_list {
  min-width: 220px;
  max-width: 420px;
  /* height: 86px; */
  overflow: hidden;
  top: 39px;
  box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.45);
  z-index: 1;
  position: absolute;
  text-align: right;
  right: 0px;
  background: #fff;
}
.header_list_options {
  color: #525252;
  width: 100%;
  float: none;
  padding: 5px 10px;
  margin: 0;
  font-size: 12px;
  border-top: none;
  border-bottom: 1px solid #f3f3f3;
  cursor: pointer;
  list-style: none;
}
.headerAction {
  display: flex;
  gap: 10px;
  padding: 10px 0;
}
.navbar__item:hover {
  background-color: #f3f3f9;
}
