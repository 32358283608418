.main-body {
  padding: 15px;
  background-color: #f2f2f7;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  display: grid;
}
.container {
  width: 100%;
}
.shdbds {
  background-color: #fff;
  padding: 7px;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: transform 0.3s ease-out;
  cursor: pointer;
}
.chartRow {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.chartItem {
  flex: 1 1 calc(50% - 10px);
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .chartRow {
    flex-direction: column;
    gap: 10px;
  }

  .chartItem {
    flex: 1 1 100%;
  }
}
