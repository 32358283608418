.modalcontainerr {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  border-bottom: 1px solid #dee2e6;
}
.modalHeader {
  align-items: flex-start;

  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.modalheading {
  line-height: 1.5px;
  color: #495057;
  font-weight: 600;
  font-size: 15px;
}
.modalbody {
  flex: 1 1 auto;
  padding: 5px 15px;
  position: relative;
  /* border-bottom: 1px solid #dee2e6; */
}
.modalform {
  text-align: left;
  display: flex;
  flex-direction: column;
}
.mdlinput {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 20px;
  font-size: 12px;
  outline: #ced4da;
  padding: 5px;
}
.closebtn {
  background-color: #f3f6f9;
  border: none;
  color: #000;
  cursor: pointer;
  padding: 6px;
  font-size: 12px;
  border-radius: 3px;
  box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px,
    rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.04) 0px 6px 6px -3px,
    rgba(14, 63, 126, 0.04) 0px 12px 12px -6px,
    rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
}
.Subbtn {
  background-color: #12468a;
  color: #fff;
  border: none;
  font-size: 12px;
  padding: 6px;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px,
    rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.04) 0px 6px 6px -3px,
    rgba(14, 63, 126, 0.04) 0px 12px 12px -6px,
    rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
}
.error {
  color: red;
  font-size: 11px;
  padding-top: 5px;
}
