.modalcontainer {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  /* width: 100%; */
  width: 500px;
  border-bottom: 1px solid #dee2e6;
  /* height: 300px; */
}
.modalHeader {
  align-items: flex-start;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.modalheading {
  line-height: 1.5px;
  color: rgb(73, 70, 70);
  font-weight: 500;
  font-size: 20px;
}
.modalbody {
  flex: 1 1 auto;
  padding: 1rem;
  position: relative;
  border-bottom: 1px solid #dee2e6;
}
.modalform {
  text-align: left;
  display: flex;
  flex-direction: column;
}
.mdlinput {
  background-clip: padding-box;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid black;
  color: #495057;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: 1.5;
  padding: 0 0 0 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  outline: none;
  padding-bottom: 15px;
}
.closebtn {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
  padding: 6px;
}
.Subbtn {
  background-color: linear-gradient(310deg, #1857a5, #28a745);
  color: #fff;
  padding: 6px;
}
