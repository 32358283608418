.outletClass {
  /* display: flex;
  display: flex; */
  /* height: calc(100vh - 40px); */
}
.outletContainer {
  /* top: 45px; */
  position: relative;
  z-index: 1;
}
.marginLeft {
  margin-left: 221px;
}
