.updownloadcon {
  padding: 15px;
  border-bottom: 1px solid #e9ebec;
  text-align: left;
}
.dnldbtnforreport {
  padding: 7px 10px;
  min-width: 130px;
  color: #fff;
  background-color: #f06548;
  border: none;
  font-size: 11px;
  cursor: pointer;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
}
.dnldbtnforreport:hover {
  box-shadow: 2px 5px 5px #deaca2;
  transform: scale(1.02) rotate(-0.5deg);
}
.detcon {
  padding: 15px;
  margin-top: 10px;
}
.btndvtoop {
  display: flex;
  padding: 5px;
  justify-content: space-between;
  border-bottom: 1px solid #e9ebec;
  padding: 15px;
  border-radius: 100px;
  background-color: rgb(18, 70, 138);
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  min-width: 140px;
  border: 1px solid rgb(221, 221, 221);
  margin-bottom: 5px;
  border-radius: 100px;
  background-color: rgb(18, 70, 138);
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  min-width: 140px;
  border: 1px solid rgb(221, 221, 221);
  box-shadow: rgba(0, 0, 0, 0.2) 2px 5px 10px;
}
.btndvtxt {
  font-size: 13px;
}
.brwdettcon {
  padding: 15px;
  text-align: left;
  display: flex;
  gap: 35px;
}
.wholetxtcon {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
.fourtxtcon {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.actcontfrtxt {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 25%;
}
.ptpfrtxt {
  font-size: 13px;
  color: #878a99;
  font-weight: 600;
}
.pbtfrtxt {
  font-size: 13px;
  color: #878a99;
}
.clrrchfrsdg {
  border: none;
  color: rgb(255, 255, 255);
  width: 80px;
  border-radius: 5px;

  height: 25px;
  background-color: #12468a;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
}
.clrrchfrsdg:hover {
  box-shadow: 2px 5px 5px #b7c9e3;
  transform: scale(1.02) rotate(-0.5deg);
}
