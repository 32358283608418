.paginationUl li {
  margin: 0;
}

.paginationUl li button {
  margin: 0;
  padding: 8px;
  font-size: 13px;
  color: #12468a !important;
  background-color: #fff;

  height: 26px;
  border-radius: unset;
}
