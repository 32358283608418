.searchBody {
  display: flex;
  justify-content: space-between;
}
.leftsidesearchcon {
  text-align: left;
}
.gmtext {
  color: #495057;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, sans-serif;
}
.secText {
  color: #878a99;
  font-size: 12px;
}
.searchinfordash {
  display: flex;
  align-items: center;
}
.searchselc {
  width: 120px;
  height: 35px;
  border: none;
  border-radius: 15px 1px 1px 15px;
  color: #212529;
  font-size: 12px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}
.probx {
  width: 35px;
  color: #fff;
  background-color: #25a546;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1px 15px 15px 1px;
  margin-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
}
.btnforsearch {
  font-size: 12px;
  color: #fff;
  padding: 8px;
  height: 35px;
  background-color: #12468a;
  border: none;
  border-radius: 100px;
  min-width: 70px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.btnforsearch:hover {
  background-color: #12468a;
  transform: scale(1.01);
  transition: all 0.1s ease-out;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.selectcontefrosearch {
  display: flex;
}
