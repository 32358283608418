.searchformasc {
  background-color: #fff;
  padding: 14px;
  display: grid;
  flex-direction: column;
  gap: 15px;
  font-family: Inter, sans-serif;
}
.searcchfrs {
  max-height: 30px;
  border: none;
  color: rgb(255, 255, 255);
  width: 80px;
  border-radius: 5px;
  position: relative;
  top: 14px;
  height: 25px;
  background-color: #45cb85;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
}
.searcchfrs:hover {
  box-shadow: rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.25) 0 1px 2px, rgba(44, 187, 99, 0.25) 0 2px 4px,
    rgba(44, 187, 99, 0.25) 0 4px 8px, rgba(44, 187, 99, 0.25) 0 8px 16px,
    rgba(44, 187, 99, 0.25) 0 16px 32px;
  transform: scale(1.02) rotate(-0.5deg);
}
.clrrchfrs {
  max-height: 30px;
  border: none;
  color: rgb(255, 255, 255);
  width: 80px;
  border-radius: 5px;
  position: relative;
  top: 14px;
  height: 25px;
  background-color: #12468a;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
}
.clrrchfrs:hover {
  box-shadow: 2px 5px 5px #b7c9e3;
  transform: scale(1.02) rotate(-0.5deg);
}
.listSrch {
  font-family: open sans;
  border-radius: 5px;

  width: 100%;
  height: 29px !important;
  font-size: 11px;
  background: white;
}
.fourinputasc {
  display: flex;

  gap: 20px;
  width: 100%;
}
.threeinputads {
  display: flex;
  gap: 20px;
  width: 74.5%;
}
.oneinputads {
  display: flex;
  gap: 20px;
  width: 23.5%;
}
.twoinputasc {
  display: flex;
  gap: 20px;
  width: 49%;
}
.searorinp {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  text-align: left;
}
.inpttsc {
  border-radius: 5px;
  border: 1px solid #e2e1e0;
  height: 18px;
  font-size: 11px;
  color: #6d6d6d;
  padding: 5px;
  font-family: Inter, sans-serif;
}
.selctasc {
  border-radius: 5px;
  border: 1px solid #e2e1e0;

  height: 30px;
  font-size: 11px;
  color: #6d6d6d;
  padding: 5px;
  font-family: Inter, sans-serif;
}
.optionnsel {
  font-size: 11px;
  font-family: open sans;
  padding: 5px;
  height: 20px;
}
.inputasc {
  font-family: open sans;
  border-radius: 5px;
  border: 1px solid #e2e1e0;
  width: 100%;
  height: 33px;
  font-size: 11px;
  color: #6d6d6d;
  padding: 0 5px;
}
.buttonasc {
  display: flex;
  gap: 20px;
}
.uplodflex {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.chosefil {
  padding: 2px 6px;
  border: solid 1px #dadada;
  width: 230px;
  font-size: 10px;
}
.downloadssam {
  font-size: 9px;
  padding: 2px;
  width: 100px;
  max-height: 25px;
  color: rgb(69, 159, 160);
  border: 1px solid #000;
  border-radius: 2px;
}
.butncon {
  display: flex;
  gap: 10px;
}
.Subbtn {
  background-color: #12468a;
  color: #fff;
  border: none;
  font-size: 13px;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px,
    rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.04) 0px 6px 6px -3px,
    rgba(14, 63, 126, 0.04) 0px 12px 12px -6px,
    rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
}
