.logincontainer {
  margin-left: auto;
  margin-right: auto;
  font-family: "serif !important";
  max-width: 696px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.loginLogo {
  margin: 85px 0px 0px;
  width: 250px;
}
.signMain {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin: 0 auto;
  max-width: 400px;
  padding: 40px 30px;
  padding-bottom: 15px;
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
}
.inputcontainer {
  border: 1px solid #999;
  border-radius: 10px;
  box-shadow: -1px 2px 2px 0 #d8d8d8;
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  /* max-width: 336px; */
}
.logininput {
  border: none;
  outline: none;
  padding: 7px 0;
  width: 83%;
}
.iconcontainer {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.downloadwrapper .btn {
  background-image: linear-gradient(310deg, #1857a5, #28a745) !important;
  /* background-color: #f8981d; */
  border: none;
  box-shadow: -2px 2px 7px 0 #d3aebf;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-size: 17px;
  font-weight: 700;
  margin-top: 20px;
  opacity: 0.9;
  padding: 9px 20px;
  text-transform: UPPERCASE;
  width: 100%;
}
.btn {
  font-family: inherit;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #212529;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
}
.forgotpw {
  float: right;
  margin: 10px;
  font-size: 16px;
  color: rgb(18, 70, 138);
}
.forgotpw:hover {
  text-decoration: underline;
}
