.main-body {
  /* height: 100%; */
  padding: 15px;
  background-color: #f2f2f7;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  display: grid;
}
.error {
  color: red;
  font-size: 11px;
  padding-top: 5px;
}
.imgofprop {
  height: 120px;
  width: 120px;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.fourtxtcon {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.actcontfrtxt {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 25%;
}
.ptpfrtxt {
  font-size: 13px;
  color: #878a99;
  font-weight: 600;
}
.pbtfrtxt {
  font-size: 13px;
  color: #878a99;
}
/* .main-body h3,
.main-body h4 {
  color: #1c9294;
} */

.container {
  width: 100%;
}
.tablco {
  padding: 10px;
  background-color: #fff;
}
.inputsearchcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectfotnu {
  width: 70px;
  border: 1px solid #ddd;
  padding: 5px 7px;
  color: #212529;
  border-radius: 5px;
}
.addbtn {
  width: 70px;
  border: none;
  padding: 5px 7px;
  color: #fff;
  border-radius: 5px;
  background-color: #45cb85;
  font-family: Inter, sans-serif;
  font-size: 12.5px;
  cursor: pointer;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
}
.inpforsearch {
  border: 1px solid #ced4da;
  height: 21px;
  border-radius: 5px 1px 1px 5px;
}
.gobtn {
  border: none;
  height: 25px;
  cursor: pointer;
  border-radius: 1px 5px 5px 1px;
  /* background-color: #fff; */
}
.gobtnicon {
  position: relative;
  top: 1px;
  color: #ced4da;
}
.tableContai {
  margin-top: 10px;
}
.paginCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.pagitext {
  color: #212529;
  font-size: 13px;
}
