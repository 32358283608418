.brdcon {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  background: white;
  border-top: 1px solid rgb(221, 221, 221);
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 1px;
}
.pagename {
  font-size: 13px;
  color: rgb(73, 80, 87);
  font-weight: bold;
  text-transform: uppercase;
  font-family: Inter, sans-serif;
}
.dashb {
  color: #212529;
  font-size: 13px;
  position: relative;
  top: 1px;
  cursor: pointer;
}
.nxtico {
  position: relative;
  top: 1px;
}
