.updownloadcon {
  padding: 15px;
  border-bottom: 1px solid #e9ebec;
  text-align: left;
}
.bleviewbtn {
  padding: 6px;
  color: #fff;
  background-color: #12468a;
  border: none;
  border-radius: 3.5px;
  /* height: 23px;
  width: 23px; */
  cursor: pointer;
  margin-right: 7px;
  font-size: 11px;
}
.dnldbtnforreport {
  padding: 5px 10px;
  border-radius: 3px;
  color: #fff;
  background-color: #f06548;
  border: none;
  font-size: 11px;
  cursor: pointer;
}
.detcon {
  padding: 15px;
  margin-top: 10px;
}
.btndvtoop {
  display: flex;
  padding: 5px;
  justify-content: space-between;
  border-bottom: 1px solid #e9ebec;
  padding: 15px;
}
.btndvtxt {
  font-size: 13px;
}
.brwdettcon {
  padding: 15px;
  text-align: left;
  display: flex;
  gap: 35px;
}
.wholetxtcon {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
.fourtxtcon {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.actcontfrtxt {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 25%;
}
.ptpfrtxt {
  font-size: 13px;
  color: #878a99;
  font-weight: 600;
}
.pbtfrtxt {
  font-size: 13px;
  color: #878a99;
}
