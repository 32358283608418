.main-body {
  /* height: 100%; */
  padding: 15px;
  background-color: #f2f2f7;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  display: grid;
}
.searorinpzrssw {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 90%;
  max-width: 235px;
  text-align: left;
}
.Subbtn {
  background-color: #12468a;
  color: #fff;
  border: none;
  font-size: 13px;
  padding: 8px;
  border-radius: 100px;
  cursor: pointer;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 19px 31px rgba(0, 0, 0, 0.2);
}
.Subbtn:hover {
  box-shadow: 2px 5px 5px #b7c9e3;
  transform: scale(1.02) rotate(-0.5deg);
}
/* .main-body h3,
.main-body h4 {
  color: #1c9294;
} */

.container {
  width: 100%;
}
.tablco {
  padding: 10px;
  background-color: #fff;
  display: grid;
}
.inputsearchcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectfotnu {
  width: 80px;
  border: 1px solid #ddd;
  padding: 5px 7px;
  color: #212529;
  border-radius: 100px;
  box-shadow: 2px 19px 31px rgba(0, 0, 0, 0.2);
}

.addbtn {
  width: 80px;
  color: #fff;
  padding: 5px 7px;
  border-radius: 100px;
  background-color: #45cb85;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 19px 31px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-size: 12px;

  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  cursor: pointer;
}
.addbtn:hover {
  box-shadow: rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.25) 0 1px 2px, rgba(44, 187, 99, 0.25) 0 2px 4px,
    rgba(44, 187, 99, 0.25) 0 4px 8px, rgba(44, 187, 99, 0.25) 0 8px 16px,
    rgba(44, 187, 99, 0.25) 0 16px 32px;
  transform: scale(1.02) rotate(-0.5deg);
}

.inpforsearch {
  border: 1px solid #ced4da;
  height: 21px;
  border-radius: 5px 1px 1px 5px;
}
.gobtn {
  border: none;
  height: 25px;
  cursor: pointer;
  background-color: #45cb85;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 19px 31px rgba(0, 0, 0, 0.2);
  color: #fff;
  /* background-color: #fff; */
}
.gobtn:hover {
  box-shadow: rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.25) 0 1px 2px, rgba(44, 187, 99, 0.25) 0 2px 4px,
    rgba(44, 187, 99, 0.25) 0 4px 8px, rgba(44, 187, 99, 0.25) 0 8px 16px,
    rgba(44, 187, 99, 0.25) 0 16px 32px;
  transform: scale(1.02) rotate(-0.5deg);
}

.gobtnicon {
  position: relative;
  top: 1px;
  color: #fff;
}
.tableContai {
  margin-top: 10px;
  width: 100%;
  overflow-x: auto;
}
.paginCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}
.pagitext {
  color: #212529;
  font-size: 13px;
}
.uploadbx {
  background-color: #fff;
  color: #212529;
  font-size: 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #ced4da;
  /* box-shadow: 5px 5px 10px #000; */
}
::-webkit-file-upload-button {
  color: #212529;
  background-color: #eff2f7;
  padding: 5px;
  border: #ced4da;
  border-radius: 5px 0px 0px 5px;
  outline: none;
  font-size: 11px;
  width: 80px;

  height: 24px;
}
