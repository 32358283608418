.main-body {
  /* height: 100%; */
  padding: 15px;
  background-color: #f2f2f7;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  display: grid;
}
.bbtnvofde {
  margin-left: 5px;
  color: #fff;
  background-color: #12468a;
  font-size: 10px;
  padding: 3px 5px;
  border-radius: 3px;
  border: none;
}
.imgofprop {
  height: 120px;
  width: 120px;
  border-radius: 5px;
  border: 0.5px solid #ddd;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.fourtxtcon {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  gap: 20px;
}
.twoinputasccfd {
  display: flex;

  gap: 5px;
}
.twoinputasc {
  display: flex;
  gap: 20px;
  width: 49%;
}
.actcontfrtxt {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 25%;
}
.ptpfrtxt {
  font-size: 13px;
  color: #878a99;
  font-weight: 600;
}
.pbtfrtxt {
  font-size: 13px;
  color: #878a99;
}
/* .main-body h3,
.main-body h4 {
  color: #1c9294;
} */

.container {
  width: 100%;
}
.tablco {
  padding: 10px;
  background-color: #fff;
}
.inputsearchcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectfotnu {
  width: 70px;
  border: 1px solid #ddd;
  padding: 5px 7px;
  color: #212529;
  border-radius: 5px;
}
.addbtn {
  width: 70px;
  border: none;
  padding: 5px 7px;
  color: #fff;
  border-radius: 5px;
  background-color: #45cb85;
  font-family: Inter, sans-serif;
  font-size: 12.5px;
  cursor: pointer;
  border-radius: 100px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
}
.inpforsearch {
  border: 1px solid #ced4da;
  height: 21px;
  border-radius: 5px 1px 1px 5px;
}
.gobtn {
  border: none;
  height: 25px;
  cursor: pointer;
  border-radius: 1px 5px 5px 1px;
  /* background-color: #fff; */
}
.gobtnicon {
  position: relative;
  top: 1px;
  color: #ced4da;
}
.tableContai {
  margin-top: 10px;
}
.paginCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.pagitext {
  color: #212529;
  font-size: 13px;
}
.searchformasc {
  background-color: #fff;
  padding: 14px;
  display: grid;
  flex-direction: column;
  gap: 15px;
  font-family: Inter, sans-serif;
}
.bbtfnvogfde {
  color: #fff;
  background-color: #12468a;
  font-size: 10px;
  padding: 3px 5px;
  border-radius: 3px;
  border: none;
}
.uploadbx {
  background-color: #fff;
  color: #212529;
  font-size: 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #ced4da;
  /* box-shadow: 5px 5px 10px #000; */
}
::-webkit-file-upload-button {
  color: #212529;
  background-color: #eff2f7;
  padding: 5px;
  border: #ced4da;
  border-radius: 5px 0px 0px 5px;
  outline: none;
  font-size: 11px;
  width: 80px;

  height: 24px;
}
.totalcheckconnn {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}
.mdselecttt {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 30px;
  font-size: 12px;
  outline: #ced4da;
  padding: 5px;
  color: #494646;
  width: 100%;
}
.bbtnvofde {
  margin-left: 5px;
  color: #fff;
  background-color: #12468a;
  font-size: 10px;
  padding: 3px 5px;
  border-radius: 3px;
  border: none;
}
.requ {
  color: red;
  font-size: 13px;
  padding-left: 2px;
}
.error {
  color: red;
  font-size: 11px;
  padding-top: 1px;
}
.fourinputasc {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  width: 100%;
}
.threeinputads {
  display: flex;
  gap: 20px;
  width: 74.5%;
}
.oneinputads {
  display: flex;
  gap: 20px;
  width: 23.5%;
}
.twoinputasc {
  display: flex;
  gap: 20px;
  width: 49%;
}
.searorinp {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  text-align: left;
}
.inpttsc {
  border-radius: 5px;
  border: 1px solid #e2e1e0;
  height: 18px;
  font-size: 11px;
  color: #6d6d6d;
  padding: 5px;
  font-family: Inter, sans-serif;
}
.inputstst {
  border-radius: 5px;
  border: 1px solid #e2e1e0;
  height: 15px;
  font-size: 11px;
  color: #6d6d6d;
  padding: 5px;
  font-family: Inter, sans-serif;
}
.selctasc {
  border-radius: 5px;
  border: 1px solid #e2e1e0;
  /* width: 100%; */
  height: 30px;
  font-size: 11px;
  color: #6d6d6d;
  padding: 5px;
  font-family: Inter, sans-serif;
}
.optionnsel {
  font-size: 11px;
  font-family: open sans;
  padding: 5px;
  height: 20px;
}
.inputasc {
  font-family: open sans;
  border-radius: 5px;
  border: 1px solid #e2e1e0;
  width: 100%;
  height: 33px;
  font-size: 11px;
  color: #6d6d6d;
  padding: 0 5px;
}
.buttonasc {
  display: flex;
  gap: 20px;
}
.uplodflex {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.chosefil {
  padding: 2px 6px;
  border: solid 1px #dadada;
  width: 230px;
  font-size: 10px;
}
.downloadssam {
  font-size: 9px;
  padding: 2px;
  width: 100px;
  max-height: 25px;
  color: rgb(69, 159, 160);
  border: 1px solid #000;
  border-radius: 2px;
}
.butncon {
  display: flex;
  gap: 10px;
}
.Subbtn {
  background-color: #12468a;
  color: #fff;
  border: none;
  font-size: 13px;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px,
    rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.04) 0px 6px 6px -3px,
    rgba(14, 63, 126, 0.04) 0px 12px 12px -6px,
    rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
}
