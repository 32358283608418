.modalcontainer {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  /* width: 100%; */
  width: 500px;
  border-bottom: 1px solid #dee2e6;
  /* height: 300px; */
}
.modalHeader {
  align-items: flex-start;
  /* border-bottom: 1px solid #dee2e6; */
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.modalheading {
  line-height: 1.5px;
  color: #495057;
  font-weight: 600;
  font-size: 15px;
}
.modalbody {
  flex: 1 1 auto;
  padding: 5px 15px;
  position: relative;
  /* border-bottom: 1px solid #dee2e6; */
}
.modalform {
  text-align: left;
  display: flex;
  flex-direction: column;
}
.mdlinput {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 20px;
  font-size: 12px;
  outline: #ced4da;
  padding: 5px;
}
.mdselecttt {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 30px;
  font-size: 12px;
  outline: #ced4da;
  padding: 5px;
  color: #494646;
}
.closebtn {
  background-color: #f3f6f9;
  border: none;
  color: #000;
  cursor: pointer;
  padding: 6px;
  font-size: 12px;
  border-radius: 3px;
  box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px,
    rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.04) 0px 6px 6px -3px,
    rgba(14, 63, 126, 0.04) 0px 12px 12px -6px,
    rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
}
.Subbtn {
  background-color: #12468a;
  color: #fff;
  border: none;
  font-size: 12px;
  padding: 6px;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px,
    rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.04) 0px 6px 6px -3px,
    rgba(14, 63, 126, 0.04) 0px 12px 12px -6px,
    rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
}
.error {
  color: red;
  font-size: 11px;
  padding-top: 5px;
}
.main-body {
  /* height: 100%; */
  padding: 15px;
  background-color: #f2f2f7;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  display: grid;
}
.notAppre {
  border-radius: 100%;
  background: red;
  display: flex;
  align-self: center;
  color: rgb(255, 255, 255);
  margin: 10px;
}
.uploadbx {
  background-color: #fff;
  color: #212529;
  font-size: 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #ced4da;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* box-shadow: 5px 5px 10px #000; */
}
::-webkit-file-upload-button {
  color: #212529;
  background-color: #eff2f7;
  padding: 5px;
  border: #ced4da;
  border-radius: 5px 0px 0px 5px;
  outline: none;
  font-size: 11px;
  width: 80px;

  height: 24px;
}
.applylonffer {
  /* padding: 7.5px; */
  background: rgb(240, 248, 255);
  text-align: left;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
}
.applylonffergg {
  display: flex;
  justify-content: space-between;
  padding: 3px 6px;
  background-color: #fff;
}
.applylonfthn {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  padding: 3px 6px;
  background-color: #fff;
  gap: 2px;
}
.divinffe {
  width: 200px;
  text-align: left;
}
.divinffdffg {
  display: flex;
  gap: 5px;
  /* width: 200px; */
  text-align: left;
}
.acptbtn {
  width: 100%;
  background-color: #45cb85;
  font-size: 13px;
  border: none;
  padding: 5px;
  color: #fff;
  font-weight: bold;
  border-radius: 0 0 5px 5px;
  cursor: pointer;
}
.aboacptbtn {
  width: 100%;
  background-color: #3a72bc;
  font-size: 13px;
  border: none;
  padding: 5px;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}
.txtfooflbtone {
  font-size: 13px;
  color: #3a72bc;
  font-weight: bold;
}
.txtfooflbtoneffs {
  font-size: 12px;
  color: #6d6d6d;
  font-weight: bold;
}
.txtfoofl {
  font-size: 12px;
  color: #6d6d6d;
}
.bnkNamet {
  font-size: 13px;
  color: #6d6d6d;
  font-weight: bold;
}
.bnkNamett {
  font-size: 11px;
  color: #6d6d6d;
  font-weight: bold;
}
.thnktt {
  font-size: 18px;
  /* color: #323d85; */
  color: #45cb85;
  font-weight: bold;
  padding-bottom: 5px;
}
.btmthnktt {
  font-size: 13px;
  color: #212529;
  font-weight: bold;
  padding-bottom: 15px;
}

.flfosmc {
  display: flex;
  justify-content: space-between;
}
.applheader {
  font-size: 15px;
  font-weight: bold;
  color: #12468a;
  text-align: left;
  padding-bottom: 10px;
}
/* .main-body h3,
.main-body h4 {
  color: #1c9294;
} */

.container {
  width: 100%;
}
.tablco {
  padding: 10px;
  background-color: #fff;
}
.inputsearchcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectfotnu {
  width: 70px;
  border: 1px solid #ddd;
  padding: 5px 7px;
  color: #212529;
  border-radius: 5px;
}
.addbtn {
  width: 70px;
  border: none;
  padding: 5px 7px;
  color: #fff;
  border-radius: 5px;
  background-color: #45cb85;
  font-family: Inter, sans-serif;
  font-size: 12.5px;
  cursor: pointer;
}
.inpforsearch {
  border: 1px solid #ced4da;
  height: 21px;
  border-radius: 5px 1px 1px 5px;
}
.gobtn {
  border: none;
  height: 25px;
  cursor: pointer;
  border-radius: 1px 5px 5px 1px;
  /* background-color: #fff; */
}
.gobtnicon {
  position: relative;
  top: 1px;
  color: #ced4da;
}
.tableContai {
  margin-top: 10px;
}
.paginCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagitext {
  color: #212529;
  font-size: 13px;
}
.totalcheckconnn {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}
.applylnx {
  padding: 10px;
  background: rgb(240, 248, 255);
  text-align: left;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
}
/* .totalcheckconnn :hover {
  background: #e3f2ff;
} */
.totalcheckconnntd {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}
.totalcheckconnntd :hover {
  background: #e3f2ff;
}
.applyluptxt {
  color: rgb(73, 80, 87);
  font-size: 16px;
  font-weight: bold;
}
.applylnlrdv {
  display: flex;
  justify-content: space-between;
  padding-top: 6px;
}
.applylnlrdvtxt {
  color: rgb(10, 97, 205);
  font-size: 12px;
  /* font-weight: bold; */
}
.main-body {
  /* height: 100%; */
  padding: 15px;
  background-color: #f2f2f7;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  display: grid;
}
.shdwtpbx {
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
/* .main-body h3,
.main-body h4 {
  color: #1c9294;
} */

.container {
  width: 100%;
}
.tablco {
  padding: 10px;
  background-color: #fff;
}
.inputsearchcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectfotnu {
  width: 70px;
  border: 1px solid #ddd;
  padding: 5px 7px;
  color: #212529;
  border-radius: 5px;
}
.addbtn {
  width: 70px;
  border: none;
  padding: 5px 7px;
  color: #fff;
  border-radius: 5px;
  background-color: #45cb85;
  font-family: Inter, sans-serif;
  font-size: 12.5px;
  cursor: pointer;
}
.inpforsearch {
  border: 1px solid #ced4da;
  height: 21px;
  border-radius: 5px 1px 1px 5px;
}
.gobtn {
  border: none;
  height: 25px;
  cursor: pointer;
  border-radius: 1px 5px 5px 1px;
  /* background-color: #fff; */
}
.gobtnicon {
  position: relative;
  top: 1px;
  color: #ced4da;
}
.tableContai {
  margin-top: 10px;
}
.paginCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagitext {
  color: #212529;
  font-size: 13px;
}
.totalcheckconnn {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}
.totalcheckfhfnn {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}

.inputstst {
  border-radius: 5px;
  border: 1px solid #e2e1e0;
  height: 15px;
  font-size: 11px;
  color: #6d6d6d;
  padding: 5px;
  font-family: Inter, sans-serif;
}
.divfrtheredr {
  padding: 3px;
  display: flex;
  /* gap: 10px; */
  align-items: center;
  background: rgb(255, 255, 255);
}
.selctasc {
  border-radius: 5px;
  border: 1px solid #e2e1e0;
  /* width: 100%; */
  height: 30px;
  font-size: 11px;
  color: #6d6d6d;
  padding: 5px;
  font-family: Inter, sans-serif;
}
.totalcheckconnnforimgsec {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
}
.searorinp {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  text-align: left;
}
.Subbtn {
  background-color: #12468a;
  color: #fff;
  border: none;
  font-size: 13px;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px,
    rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.04) 0px 6px 6px -3px,
    rgba(14, 63, 126, 0.04) 0px 12px 12px -6px,
    rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.Subbtn:hover {
  transform: scale(1.02);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.requ {
  color: red;
  font-size: 13px;
  padding-left: 2px;
}
.error {
  color: red;
  font-size: 11px;
  padding-top: 1px;
}
