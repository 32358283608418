.container {
  background-color: #12468a;

  /* background: linear-gradient(320deg, #1c6088, #339c86, #12468a, #12468a),
    url(https://www.transparenttextures.com/patterns/asfalt-light.png); */
  background: linear-gradient(320deg, #1c6088, #339c86, #12468a, #12468a);
  box-shadow: 2px 19px 31px rgba(0, 0, 0, 0.2);
  background-size: cover, auto;
  background-repeat: no-repeat, repeat;
  background-blend-mode: overlay;
  backdrop-filter: blur(20px) saturate(150%);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
  min-width: 215px;
  height: 100%;
  height: 92%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  height: 100%;
  z-index: 2;
}
/* .container {
  background-color: #3f69a0;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 19px 31px rgba(0, 0, 0, 0.2);
  background-size: cover, auto;
  background-repeat: no-repeat, repeat;
  background-blend-mode: overlay;
  backdrop-filter: blur(20px) saturate(150%);
  -webkit-backdrop-filter: blur(20px) saturate(150%);
  min-width: 215px;
  height: 100%;
  height: 92%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  height: 100%;
  z-index: 2;
} */
/* 
.container {
  background: radial-gradient(circle at 10% 10%, #12468a 20%, transparent 50%),
    radial-gradient(circle at 90% 30%, #128c74 15%, transparent 60%),
    radial-gradient(
      circle at 30% 80%,
      rgba(21, 91, 154, 0.7) 95%,
      transparent 40%
    ),
    radial-gradient(
      circle at 70% 70%,
      rgba(44, 187, 99, 0.6) 50%,
      transparent 55%
    );
  background-color: #12468a;

  min-width: 215px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  z-index: 2;
} */
.container::before {
  transition: all 0.3s ease-in-out;
}
.container li {
  list-style: none;
  text-decoration: none;
  cursor: pointer;
  color: #737373;
  /* padding: 0; */
}
.container li:hover {
  color: #2d2d2d;
}
.item-label {
  /* font-family: "Open Sans", sans-serif; */
  color: #ececed;
  font-size: 0.725rem;
  font-weight: 700;
  font-family: Inter, sans-serif;
  text-transform: capitalize;
}
.active {
  /* font-family: "Open Sans", sans-serif; */
  color: #fff;
  font-size: 0.725rem;
  font-weight: 700;
  font-family: Inter, sans-serif;
  text-transform: capitalize;
}
.link {
  text-decoration: none;
  color: inherit;
  min-height: 34px;
  cursor: pointer;
  width: 100%;
  /* border-top: 1px solid #f3f3f3; */
  display: flex;
  gap: 10px;
  align-items: center;
  line-height: 30px;
  margin: 0;

  text-decoration: none;
  /* color: #737373; */
  -webkit-text-shadow: none !important;
  text-shadow: none !important;
}
.submenulink {
  padding-left: 22px;
  text-decoration: none;
  color: inherit;
}
.cont ul {
  padding: 0;
}
.mainitem {
  /* font-size: 15px;
  font-weight: bold; */
  color: #fff;
  /* font-family: "opensans-semibold"; */
  padding: 10px 24px;
  margin: 0 0 3px 0;
  /* background: #f1f1f1; */
  cursor: pointer;
  text-align: left;
  display: flex;
  justify-content: space-between;
  background-color: #3f69a0;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 19px 31px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}
.mainitem:hover {
  transform: scale(1.009);
  box-shadow: 2px 19px 50px rgba(0, 0, 0, 0.2);
}
h2 {
  margin: 0;
  padding: 0;
}
.submenu {
  min-height: 34px;
  cursor: pointer;
  width: 100%;
  display: block;
  line-height: 30px;
  margin: 0;
  text-decoration: none;
  color: #ebebeb;
  -webkit-text-shadow: none !important;
  text-shadow: none !important;
  font-size: 11px;
}
.submenune {
  color: #cacddc;
  margin: 0;
  font-size: 11px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 15px 10px;
  max-width: 162px;
}
.activesubmenune {
  color: #fff;
  margin: 0;
  font-size: 11px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 15px 10px;
  max-width: 162px;
}

.submenuCont {
  padding: 0;
  margin: 0;
}
.submenu ul :before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 19px;
  top: 0;
  bottom: 0px;
  border-left: 1px solid #d2d2d2;
}
.submenu li ul > li:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 5px;
  height: 5px;
  /* left: 17px; */
  top: 16px;
  background-color: #fff;
  border: 1px solid #b7b7b7;
  z-index: 2;
}
/* .submenu ul > li:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 5px;
  height: 5px;
  top: 15px;
  background-color: #fff;
  border: 1px solid #b7b7b7;
  z-index: 2;
  left: 17px;
}
.submenu ul li {
  position: relative;
}
.submenu ul {
  padding-left: unset;
} */
.menuList {
  /* padding-left: 15px; */
  padding: 0px;
}
/* .submenu ul {
  padding-left: 10px;
} */
.icon {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: bold;
  color: #e2e3ea;
}
.activeicon {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: bold;
  color: #fff;
}

.icntb:hover {
  color: #fff;
}
.icon:hover {
  color: #fff;
}
.iconContainer {
  display: flex;
  align-items: center;
  /* min-height: 40px; */
  justify-content: space-between;
  padding: 10px;
  font-size: 11px;
}
.iconContainer:hover svg {
  color: #26cacc;
}
.mainitem:hover h2 {
  color: #fff;
}
.submenu svg {
  font-size: 11px;
}
/* .active {
  box-shadow: 0px 2px 0px 0 rgba(0, 0, 0, 0.15);
} */
.containerNav {
  position: fixed;
  left: 0;
  display: block;
  background-color: #fff;
  overflow-x: hidden;
  width: 220px;
  /* top: 41px; */
  height: 100%;
  margin-bottom: 30px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.cutBtn {
  padding: 10px 5px;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  display: flex;
  align-items: center;
}
.submenuCon {
  color: #cacddc;
  padding: 0 15px;
  color: #cacddc;
  font-size: 0.725rem;
  font-weight: 700;
  font-family: Inter, sans-serif;
  text-transform: capitalize;
  margin: 0;
  text-align: left;
}
.subme {
  padding: 10px;
  margin-left: 5px;
}
.subme:hover {
  color: #fff;
}
.headerlogonav {
  display: flex;
  justify-content: space-between;
  background: #f6f6f6;
  padding: 5px 7px;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 2px solid rgba(255, 255, 255, 0.3);
  margin: 7px 5px 0px 5px;

  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 19px 31px rgba(0, 0, 0, 0.2);
}
