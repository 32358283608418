.container {
  font-size: 11px;
  position: fixed;
  top: 0;
  left: 45%;
  display: flex;
  z-index: 100;
  color: #212529;
}

.confirmBox {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  display: grid;
  width: 250px;
  /* flex-direction: column; */
  align-items: center;
  gap: 5px;
}
.btnContainer {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.btnContainer button {
  font-size: 11px;
  min-width: 50px;
  height: 20px;
  width: auto;
  background: white;
  color: #12468a !important;
  border: none;
}
.btnContainer button:hover {
  background: #12468a;
  color: white !important;
}
.confirmTitle {
  font-size: 13px;
  font-weight: 600;
  display: grid;
  align-items: center;
  justify-content: center;
}
.contentTitle {
  /* display: flex;
  justify-content: center; */
  overflow: hidden;
  /* white-space: nowrap;
  text-overflow: ellipsis; */
  text-align: center;
}
.confirmTitle p {
  text-align: center;
}
.confirmMessage {
  text-align: center;
  text-wrap: balance;
  font-size: 13px;
  font-weight: 600;
}
.sbmt {
  font-size: 12px;
  min-width: 100px;
  height: 25px;
  width: auto;
  border: none;
  background: #12468a;
  color: #ffffff !important;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 19px 31px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
}
.clear {
  border-radius: 100px;
  font-size: 12px;
  min-width: 100px;
  height: 25px;
  width: auto;
  color: #fff;
  border: none;
  background: #45cb85;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: 2px 19px 31px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  /* background: #eeeeee; */
  /* border: 1px solid #e5e5e5; */
}
.clear:hover {
  box-shadow: 2px 5px 5px #6ed29e;
  transform: scale(1.02) rotate(-0.5deg);
}
.sbmt:hover {
  box-shadow: 2px 5px 5px #b7c9e3;
  transform: scale(1.02) rotate(-0.5deg);
}
.submitBtn {
  display: flex;
  gap: 15px;
  justify-content: center;
}
.boxContiner {
  width: 400px;
  min-height: 200px;
  background: #fff;
  padding: 10px 10px;
  /* display: flex;
  flex-direction: column; */
  position: relative;
  display: grid;
  border-radius: 10px;
  box-shadow: 2px 19px 31px rgba(0, 0, 0, 0.2);
}
.boxContiner h3 {
  color: #12468a;
  margin: 0;
  padding: 0;
  /* padding-bottom: 15px; */
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
}
/* .boxContiner > *:nth-child(n) {
  flex: 1;
} */
.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.3); */
  border-bottom: 2px solid #ddd;
  /* border-image: linear-gradient(
      0.25turn,
      rgb(255, 255, 255),
      rgb(11, 127, 128),
      rgb(255, 255, 255)
    )
    1 / 1 / 0 stretch; */
  /* padding-bottom: 8px; */
}

.content {
  display: grid;
  /* justify-content: center; */
  color: #212529;
  gap: 10px;
}
